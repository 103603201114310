import styled from 'styled-components';
import * as colors from '../config/colors';
import 'react-toastify/dist/ReactToastify.css';

export const QueryContainer = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   justify-content: start;
   margin: 24px 0;
   row-gap: 24px;
   border: 1px solid ${colors.lightBgText};
   padding: 24px;
   border-radius: 8px;
`;

export const QueryForm = styled.div`
   display: flex;
   flex-direction: column;
   row-gap: 24px;
   border: 1px solid ${colors.lightBgText};
   border-radius: 8px;
   width: 100%;
   padding: 24px;

   &:focus-within {
      border: 1px solid ${colors.primaryColor};
   }

   h3 {
      text-align: left;
   }

   input {
      width: 80px;
      text-align: right;
      padding: 8px;
      font-size: 1.1em;
   }

   label {
      font-size: 0.8em;
      margin-bottom: 4px;
   }

   div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 24px;
   }

   .form-fields {
      flex-wrap: wrap;
      row-gap: 12px;
      display: flex;
      justify-content: start;
   }

   .date-fields {
      display: flex;
      flex-direction: column;
      justify-content: start;
      width: auto;
   }

   .input-fields {
      display: flex;
      flex-direction: column;
      justify-content: start;
      min-width: 120px;
   }
   .input-fields > input {
      width: 100%;
   }
   .input-fields-sm {
      display: flex;
      flex-direction: column;
      justify-content: start;
      width: 120px;
   }

   .input-fields-sm > input {
      width: 100%;
   }

   .date-fields > input {
      width: 160px;
      font-family: 'Noto Sans', sans-serif;
      text-align: center;
   }
`;
