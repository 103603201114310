import React, { useState } from 'react';
import { Container } from '../../styles/GlobalStyles';
import { BrandForm } from './styled';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { get } from 'lodash';

export default function NewProdBrand() {
   const [isLoading, setIsLoading] = useState(false);
   const [newBrand, setNewBrand] = useState('');

   const handleAddBrand = async (e) => {
      e.preventDefault();
      try {
         setIsLoading(true);
         const brand = {
            name: newBrand,
         };
         await axios.post('/prodbrands', brand);
         toast.success(`Categoria "${newBrand}" foi cadastrada com sucesso.`);
         handleClearFields();
         setIsLoading(false);
      } catch (err) {
         const errors = get(err, 'response.data.errors', []);
         toast.error(errors);
         setIsLoading(false);
      }
   };

   const handleClearFields = () => {
      setNewBrand('');
   };
   return (
      <Container>
         <Loading isLoading={isLoading} />
         <h3>Cadastre uma nova categoria</h3>
         <BrandForm>
            <label>Nome da marca:</label>
            <input
               type="text"
               value={newBrand}
               onChange={(e) => setNewBrand(e.target.value)}
            ></input>
            <button onClick={handleAddBrand}>Cadastrar</button>
         </BrandForm>
      </Container>
   );
}
