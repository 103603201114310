import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Login from '../pages/Login';
import Page404 from '../pages/Page404';
import PrivateRoute from './PrivateRoute';
import Products from '../pages/Products';
import NewProduct from '../pages/NewProduct';
import ProdQuery from '../pages/ProdQuery';
import Movements from '../pages/Movements';
import MovementIn from '../pages/MovementIn';
import MovementOut from '../pages/MovementOut';
import SearchStock from '../pages/SearchStock';
import Home from '../pages/Home';
import Unauthorized from '../pages/Unauthorized';
import Alunos from '../pages/Alunos';
import Register from '../pages/Register';
import EditUser from '../pages/EditUser';
import Main from '../pages/Main';
import Movement from '../pages/Movement';
import ProductEdit from '../pages/ProductEdit';
import Categories from '../pages/Categories';
import NewProdCategory from '../pages/NewProdCategory';
import Brands from '../pages/Brands';
import AdvancedProductSearch from '../pages/AdvancedProductSearch';

export default function Routing() {
   return (
      <Routes>
         <Route exact path="/" element={<Home />} />
         <Route exact path="/login" element={<Login />} />
         <Route exact path="/alunos" element={<Alunos />} />
         <Route exact path="/users" element={<Register />} />
         <Route
            exact
            path="/main"
            element={<PrivateRoute element={Main} isClosed={true} />}
         />
         <Route
            exact
            path="/categories"
            element={<PrivateRoute element={Categories} isClosed={true} />}
         />
         <Route
            exact
            path="/newcategory"
            element={<PrivateRoute element={NewProdCategory} isClosed={true} />}
         />

         {/* PRODUCT ROUTES */}
         <Route
            exact
            path="/product"
            element={<PrivateRoute element={NewProduct} isClosed={true} />}
         />
         <Route
            exact
            path="/products"
            element={<PrivateRoute element={Products} isClosed={true} />}
         />
         <Route
            exact
            path="/product-query"
            element={<PrivateRoute element={ProdQuery} isClosed={true} />}
         />
         <Route
            exact
            path="/products/:id"
            element={<PrivateRoute element={ProductEdit} isClosed={true} />}
         />
         <Route
            exact
            path="/prodbrands"
            element={<PrivateRoute element={Brands} isClosed={true} />}
         />

         {/* MOVEMENT ROUTES */}
         <Route
            exact
            path="/movements"
            element={<PrivateRoute element={Movements} isClosed={true} />}
         />
         <Route
            exact
            path="/movements/:id"
            element={<PrivateRoute element={Movement} isClosed={true} />}
         />
         <Route
            exact
            path="/movement-in"
            element={<PrivateRoute element={MovementIn} isClosed={true} />}
         />
         <Route
            exact
            path="/movement-out"
            element={<PrivateRoute element={MovementOut} isClosed={true} />}
         />
         <Route
            path="/edituser"
            element={<PrivateRoute element={EditUser} isClosed={true} />}
         />
         <Route
            exact
            path="/searchstock"
            element={<PrivateRoute element={SearchStock} isClosed={true} />}
         />
         {/* ADMIN ROUTES */}
         <Route
            exact
            path="/advancedstocksearch"
            element={
               <PrivateRoute element={AdvancedProductSearch} isClosed={true} />
            }
         />

         <Route path="/unauthorized" element={<Unauthorized />} />
         <Route path="/*" element={<Page404 />} />
      </Routes>
   );
}
