import styled from 'styled-components';
import * as colors from '../../config/colors';
import logoImg from '../../assets/logo-myshop.png';

export const UserPhoto = styled.div`
   background-image: url(${logoImg});
   background-size: contain;
   background-position: center;
   background-size: 70%;
   background-color: rgb(0, 0, 0, 0.05);
   background-repeat: no-repeat;
   height: 48px;
   width: 48px;
   border-radius: 100%;
`;

export const UserNavContainer = styled.div`
   height: 80px;
   min-height: 80px;
   width: 240px;
   display: flex;
   align-items: center;
   justify-content: space-around;
   margin: 24px 0;
`;
