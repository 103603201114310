import React, { useState } from 'react';

import Loading from '../../components/Loading';
import {
   Container,
   TableHeader,
   TableData,
   TableFooter,
   TrDisabledAction,
} from '../../styles/GlobalStyles';
import { BrandButtons } from './styled';
import axios from '../../services/axios';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';

export default function Brands() {
   const [brands, setBrands] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   const handleQueryBrands = async () => {
      try {
         setIsLoading(true);
         const data = await axios.get('/prodbrands');
         setBrands(data.data);
         setIsLoading(false);
      } catch (err) {
         const errors = get(err, 'response.data.errors', []);
         errors.map((error) => toast.error(error));
         setIsLoading(false);
      }
   };

   const navigate = useNavigate();
   const handleNewCategory = () => {
      navigate('/newbrand');
   };

   return (
      <Container>
         <Loading isLoading={isLoading} />
         <h1>Marcas de produtos</h1>
         <BrandButtons>
            <button onClick={handleQueryBrands}>Pesquisar marcas</button>
            <button onClick={handleNewCategory}>Cadastrar nova marca</button>
         </BrandButtons>
         {brands.length === 0 ? (
            ''
         ) : (
            <>
               <TableHeader>
                  <p>Total de marcas cadastradas: {brands.length}</p>
               </TableHeader>
               <TableData>
                  <table>
                     <thead>
                        <tr>
                           <th>ID</th>
                           <th>Nome</th>
                        </tr>
                     </thead>
                     <tbody>
                        {brands.map((brand) => (
                           <TrDisabledAction key={String(brand.id)}>
                              <td className="td-small-w">{brand.id}</td>
                              <td>{brand.name}</td>
                           </TrDisabledAction>
                        ))}
                     </tbody>
                  </table>
               </TableData>
               <TableFooter>
                  <h3> </h3>
               </TableFooter>
            </>
         )}
      </Container>
   );
}
