import React, { useEffect, useState } from 'react';
import {
   Container,
   ProductLargeFields,
   TableData,
   TableFooter,
   TableHeader,
} from '../../styles/GlobalStyles';
import CurrencyCell from '../../components/MinorUtilities/CurrencyCell';
import axios from '../../services/axios';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { Pagination } from '../Products/styled';
import { get } from 'lodash';

export default function AdvancedProductSearch() {
   const [categories, setCategories] = useState([]);
   const [brands, setBrands] = useState([]);
   const [pdvs, setPdvs] = useState([]);

   const [selectedPdvName, setSelectedPdvName] = useState('TODOS'); // Novo estado para armazenar o nome do PDV

   const [products, setProducts] = useState([]);

   const [isLoading, setIsLoading] = useState(false);

   const [currentPage, setCurrentPage] = useState(1);
   const itemsPerPage = 15;

   const [query, setQuery] = useState([]);

   useEffect(() => {
      async function getCategories() {
         try {
            setIsLoading(true);
            const categories = await axios.get('/prodcategories');
            const brands = await axios.get('/prodbrands');
            const pdvs = await axios.get('/pdvs');

            const promises = [categories.data, brands.data, pdvs.data];

            Promise.all(promises).then(
               setCategories(promises[0]),
               setBrands(promises[1]),
               setPdvs(promises[2])
            );
            setIsLoading(false);
         } catch (err) {
            const errors = get(err, 'response.data.errors', []);
            errors.map((error) => toast.error(error));
            setIsLoading(false);
         }
      }
      getCategories();
   }, []);

   const handleGetProducts = async (e) => {
      e.preventDefault();

      try {
         setIsLoading(true);
         const response = await axios.post('/advancedstocksearch', query);
         setProducts(response.data);

         // Atualize o nome do PDV selecionado com base no id
         if (query.pdv_id) {
            const selectedPdv = pdvs.find(
               (pdv) => pdv.id === parseInt(query.pdv_id)
            );
            setSelectedPdvName(selectedPdv ? selectedPdv.pdv_name : 'TODOS');
         } else {
            setSelectedPdvName('TODOS');
         }

         setIsLoading(false);
      } catch (err) {
         const errors = get(err, 'response.data.errors', []);
         errors.map((error) => {
            toast.error(error);
            setIsLoading(false);
         });
      }
   };

   const handleChangeParams = (e) => {
      const { name, value } = e.target;

      setQuery((prevParams) => ({
         ...prevParams,
         [name]: value, // Atualiza o campo correspondente usando o nome do input
      }));
   };

   const handleRowClick = (productId) => {
      window.open(`/products/${productId}`, '_blank', 'noopener,noreferrer');
   };

   // Calcula o índice do primeiro e do último item a ser exibido na página atual
   const indexOfLastProduct = currentPage * itemsPerPage;
   const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;

   // Cria uma lista de produtos para exibir na página atual
   const currentProducts = products.slice(
      indexOfFirstProduct,
      indexOfLastProduct
   );

   // Calcula o número total de páginas
   const totalPages = Math.ceil(products.length / itemsPerPage);

   const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
   };

   // Lógica para renderizar somente 5 itens por vez na paginação
   const renderPageNumbers = () => {
      const pageNumbers = [];
      const maxPageNumbersToShow = 5;
      const halfRange = Math.floor(maxPageNumbersToShow / 2);

      let startPage = Math.max(1, currentPage - halfRange);
      let endPage = Math.min(totalPages, currentPage + halfRange);

      if (currentPage <= halfRange) {
         endPage = Math.min(totalPages, maxPageNumbersToShow);
      }

      if (currentPage + halfRange >= totalPages) {
         startPage = Math.max(1, totalPages - maxPageNumbersToShow + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
         pageNumbers.push(
            <button
               key={i}
               onClick={() => handlePageChange(i)}
               className={currentPage === i ? 'active' : null}
            >
               {i}
            </button>
         );
      }

      return pageNumbers;
   };

   return (
      <Container>
         <Loading isLoading={isLoading} />
         <h3>PESQUISA AVANÇADA DE ESTOQUE</h3>
         <p className="p-left-align">
            Consulte de forma detalhada a situação do estoque dos pontos de
            venda.
         </p>
         <form onSubmit={handleGetProducts}>
            <ProductLargeFields>
               <h4>Parâmetros gerais</h4>
               <div className="row-fields">
                  <div className="fields fields-large">
                     <label htmlFor="pdv_id">Ponto de venda:</label>
                     <select
                        name="pdv_id"
                        id="pdv_id"
                        value={query.pdv_id}
                        onChange={(e) => handleChangeParams(e)}
                     >
                        <option value="">Todos</option>
                        {pdvs.map((pdv) => (
                           <option key={String(pdv.id)} value={pdv.id}>
                              {pdv.pdv_name}
                           </option>
                        ))}
                     </select>
                  </div>
               </div>
            </ProductLargeFields>
            <ProductLargeFields>
               <h4>Parâmetros de produtos</h4>
               <div className="row-fields">
                  <div className="fields-30">
                     <label htmlFor="brand_id">Marca:</label>
                     <select
                        name="brand_id"
                        id="brand_id"
                        value={query.brand_id}
                        onChange={(e) => handleChangeParams(e)}
                     >
                        <option value="">Todas</option>
                        {brands.map((brand) => (
                           <option key={String(brand.id)} value={brand.id}>
                              {brand.name}
                           </option>
                        ))}
                     </select>
                  </div>
                  <div className="fields-30">
                     <label htmlFor="category_id">Categoria:</label>
                     <select
                        name="category_id"
                        id="category_id"
                        value={query.category_id}
                        onChange={(e) => handleChangeParams(e)}
                     >
                        <option value="">Todas</option>
                        {categories.map((category) => (
                           <option
                              key={String(category.id)}
                              value={category.id}
                           >
                              {category.name}
                           </option>
                        ))}
                     </select>
                  </div>

                  {/* Isso precisa ser arrumado no back end antes de ser disponibilizado. */}
                  {/* <div className="fields-30">
                     <label htmlFor="min_stock">Estoque</label>
                     <select
                        name="below_min_stock"
                        value={query.below_min_stock}
                        onChange={(e) => handleChangeParams(e)}
                        id="min_stock"
                     >
                        <option value="">Sem filtro de estoque</option>
                        <option value={true}>Abaixo do estoque mínimo</option>
                     </select>
                  </div> */}
               </div>
            </ProductLargeFields>
            <button type="submit">Pesquisar</button>
         </form>
         {products.length > 0 ? (
            <>
               <TableHeader>
                  <p>
                     PDV selecionado: <strong>{selectedPdvName}</strong> | Total
                     de produtos: <strong>{products.length}</strong>
                  </p>
               </TableHeader>
               <TableData>
                  <table>
                     <thead>
                        <tr>
                           <th>ID</th>
                           <th>SKU</th>
                           <th className="th-extralarge-w">Nome produto</th>
                           <th>Estoque mín.</th>
                           <th>Preço de custo</th>
                           <th>Qtd.</th>
                           <th>Valor total</th>
                        </tr>
                     </thead>
                     <tbody>
                        {currentProducts.map((product) => (
                           <tr
                              key={String(product.id)}
                              onDoubleClick={() => handleRowClick(product.id)}
                           >
                              <td>{product.id}</td>
                              <td>{product.prod_sku}</td>
                              <td title={product.prod_name}>
                                 {product.prod_name}
                              </td>
                              <td>{product.min_stock}</td>
                              <CurrencyCell value={product.cost_price} />
                              <td>{product.qtd}</td>
                              <CurrencyCell value={product.total_value} />
                           </tr>
                        ))}
                     </tbody>
                  </table>
               </TableData>
               <TableFooter>
                  <p>
                     Total de páginas: <strong>{totalPages}</strong>
                  </p>
               </TableFooter>
               <Pagination>
                  <button
                     onClick={() => handlePageChange(currentPage - 1)}
                     disabled={currentPage === 1}
                  >
                     Anterior
                  </button>
                  {renderPageNumbers()}
                  <button
                     onClick={() => handlePageChange(currentPage + 1)}
                     disabled={currentPage === totalPages}
                  >
                     Próximo
                  </button>
               </Pagination>
            </>
         ) : (
            ''
         )}
      </Container>
   );
}
