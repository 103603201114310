import React from 'react';
import PropTypes from 'prop-types';
import { formatDecimalToCurrency } from '../../utils/formatCurrency';

const CurrencyCell = ({ value }) => {
   // Verifica se o valor está definido, senão utiliza 0.00
   const formattedValue = value !== undefined && value !== null ? value : 0.0;

   return <td>{formatDecimalToCurrency(formattedValue)}</td>;
};

export default CurrencyCell;

CurrencyCell.propTypes = {
   value: PropTypes.any.isRequired,
};
