import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../store/modules/auth/actions';
import { useNavigate } from 'react-router-dom';
import {
   FaPlusCircle,
   FaMinusCircle,
   FaBoxOpen,
   FaBoxes,
   FaSearchengin,
   FaPaste,
   FaSignOutAlt,
} from 'react-icons/fa';

export default function MenuItems() {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const handleLogout = (e) => {
      e.preventDefault();

      const response = window.confirm('Tem certeza que deseja sair?');

      if (!response) return;

      dispatch(actions.loginFailure());
      navigate('/login');
   };

   const iconSize = 18;

   return (
      <div className="menu-items">
         <h3>Menu de ações</h3>
         <Link to="/movement-in" title="Entrada">
            <FaPlusCircle size={iconSize} />
            <p>Entrada de mercadoria</p>
         </Link>
         <Link to="/movement-out" title="Saída">
            <FaMinusCircle size={iconSize} />
            <p>Saída de mercadoria</p>
         </Link>
         <Link to="/categories" title="Categorias de produtos">
            <FaBoxOpen size={iconSize} />
            <p>Categorias de produtos</p>
         </Link>
         <Link to="/prodbrands" title="Marcas de produtos">
            <FaBoxOpen size={iconSize} />
            <p>Marcas de produtos</p>
         </Link>
         <Link to="/product" title="Produto">
            <FaBoxOpen size={iconSize} />
            <p>Novo Produto</p>
         </Link>
         <Link to="/products" title="Produtos">
            <FaBoxes size={iconSize} />
            <p>Lista de produtos</p>
         </Link>
         <Link to="/product-query" title="prod-query">
            <FaSearchengin size={iconSize} />
            <p>Pesquisar estoque disponível</p>
         </Link>
         <Link to="/movements" title="Movimentos">
            <FaPaste size={iconSize} />
            <p>Consultar movimentos</p>
         </Link>
         <Link to="/advancedstocksearch" title="Pesquisa avançada de estoque">
            <FaPaste size={iconSize} />
            <p>Inventário</p>
         </Link>
         <Link onClick={handleLogout} to="/login" title="Sair do sistema">
            <FaSignOutAlt size={iconSize} />
            <p>Sair do sistema</p>
         </Link>
      </div>
   );
}
