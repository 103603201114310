/* eslint-disable no-unused-vars */
/* eslint-disable require-yield */
import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as actions from './actions';
import * as types from '../types';
import axios from '../../../services/axios';
import { get } from 'lodash';
import store from '../../../store/index';

// Interceptor para lidar com erros 401
axios.interceptors.response.use(
   (response) => response, // Retorna a resposta se não houver erro
   (error) => {
      const status = get(error, 'response.status', 0);

      // Se o erro for 401, sinaliza o problema de autenticação
      if (status === 401) {
         // Dispara a ação registerFailure via Redux (poderia ser outra action específica também)
         store.dispatch(actions.registerFailure());
         toast.error('Sessão expirada. Por favor, faça login novamente.');
      }

      return Promise.reject(error); // Continua a propagação do erro
   }
);

function* loginRequest({ payload }) {
   const { email, password, prevPath, navigate } = payload;
   try {
      const response = yield call(axios.post, '/tokens', {
         email,
         password,
      });
      yield put(actions.loginSuccess({ ...response.data }));

      toast.success('Login bem-sucedido.');

      axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;

      navigate(prevPath || '/');
   } catch (err) {
      toast.error('Usuário ou senha inválidos.');
      yield put(actions.loginFailure());
   }
}

function persistRehydrate({ payload }) {
   const token = get(payload, 'auth.token', '');
   if (!token) return;
   axios.defaults.headers.Authorization = `Bearer ${token}`;
}

function* registerRequest({ payload }) {
   const { id, nome, email, password } = payload;

   try {
      if (id) {
         yield call(axios.put, '/users', {
            email,
            nome,
            password: password || undefined,
         });
         toast.success('Conta alterada com sucesso.');
         yield put(actions.registerSuccess({ nome, email, password }));
      }
   } catch (err) {
      const errors = get(err, 'response.data.errors', []);
      const status = get(err, 'response.status', 0);

      if (errors.length > 0) {
         errors.map((error) => toast.error(error));
      } else {
         toast.error('Erro desconhecido');
      }

      yield put(actions.registerFailure());
   }
}

export default all([
   takeLatest(types.LOGIN_REQUEST, loginRequest),
   takeLatest(types.PERSIST_REHYDRATE, persistRehydrate),
   takeLatest(types.REGISTER_REQUEST, registerRequest),
]);
