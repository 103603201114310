import React, { useEffect } from 'react';
import { FaCircle, FaUser } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { UserPhoto, UserNavContainer } from './UserInfoStyled';

export default function UserInfo({ user, isLoggedIn }) {
   return (
      <UserNavContainer>
         {isLoggedIn ? (
            <>
               <FaCircle size={12} color="#5ecc69" title="Online" />
               <UserPhoto />
               <div>
                  <p>Usuário: {user.nome}</p>
                  <p>ID: {user.id}</p>
               </div>
            </>
         ) : (
            <p>Olá usuário! Acesse o sistema para ter acesso aos recursos.</p>
         )}
      </UserNavContainer>
   );
}

UserInfo.propTypes = {
   user: PropTypes.object,
   isLoggedIn: PropTypes.bool,
};
