import React, { useState } from 'react';

import Loading from '../../components/Loading';
import {
   Container,
   TableHeader,
   TableData,
   TableFooter,
   TrDisabledAction,
} from '../../styles/GlobalStyles';
import { CategoryParams } from './styled';
import axios from '../../services/axios';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';

export default function Categories() {
   const [categories, setCategories] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   const handleQueryCategories = async () => {
      try {
         setIsLoading(true);
         const data = await axios.get('/prodcategories');
         setCategories(data.data);
         setIsLoading(false);
      } catch (err) {
         const errors = get(err, 'response.data.errors', []);
         errors.map((error) => toast.error(error));
         setIsLoading(false);
      }
   };

   const navigate = useNavigate();
   const handleNewCategory = () => {
      navigate('/newcategory');
   };

   return (
      <Container>
         <Loading isLoading={isLoading} />
         <h1>Categorias de produtos</h1>
         <CategoryParams>
            <button onClick={handleQueryCategories}>
               Pesquisar categorias
            </button>
            <button onClick={handleNewCategory}>
               Cadastrar nova categoria
            </button>
         </CategoryParams>
         {categories.length === 0 ? (
            ''
         ) : (
            <>
               <TableHeader>
                  <p>Total de categorias cadastradas: {categories.length}</p>
               </TableHeader>
               <TableData>
                  <table>
                     <thead>
                        <tr>
                           <th>ID</th>
                           <th>Nome</th>
                        </tr>
                     </thead>
                     <tbody>
                        {categories.map((category) => (
                           <TrDisabledAction key={String(category.id)}>
                              <td className="td-small-w">{category.id}</td>
                              <td>{category.name}</td>
                           </TrDisabledAction>
                        ))}
                     </tbody>
                  </table>
               </TableData>
               <TableFooter>
                  <h3> </h3>
               </TableFooter>
            </>
         )}
      </Container>
   );
}
