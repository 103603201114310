import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loading from '../../components/Loading';

import axios from '../../services/axios';

import {
   Container,
   TableData,
   TableFooter,
   TableHeader,
} from '../../styles/GlobalStyles';
import { Title, Pagination } from './styled';
import { QueryContainer, QueryForm } from '../../styles/QueryForms';
import { toast } from 'react-toastify';
import { PesquisarBtn } from '../../styles/Buttons';

export default function Movements() {
   const [pdvs, setPdvs] = useState([]);
   const [movements, setMovements] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);

   // query parameters
   const [startDate, setStartDate] = useState('');
   const [endDate, setEndDate] = useState('');
   const [id, setId] = useState('');
   const [pdvId, setPdvId] = useState('');
   const [moveType, setMoveType] = useState('');
   const [docNumber, setDocNumber] = useState('');
   const [movementFlag, setMovementFlag] = useState('');

   const itemsPerPage = 15;

   useEffect(() => {
      async function getData() {
         try {
            setIsLoading(true);
            //const response = await axios.get('/movement-search');
            const pdvResponse = await axios.get('/pdvs');
            setPdvs(pdvResponse.data);
            setIsLoading(false);
         } catch (err) {
            const [error] = err.response.data.errors;
            toast.error(error);
            toast.warn('Redirecionando para a página de login');
            console.log(error);
            setIsLoading(false);
         }
      }
      getData();
   }, []);

   const handleQueryParams = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      const query = {
         id: id,
         pdv_id: pdvId,
         move_type: moveType,
         doc_number: docNumber,
         movement_flag: movementFlag,
         start_date: startDate,
         end_date: endDate,
      };

      if (!startDate || !endDate) {
         setIsLoading(false);
         return toast.error('Informe um intervalo de datas');
      }

      if (startDate > endDate) {
         setIsLoading(false);
         return toast.error(
            'A data de início não pode ser maior que a data final.'
         );
      }

      try {
         const response = await axios.post('/movement-search', query);
         setMovements(response.data || []); // Aqui, garantimos que `movements` seja sempre um array.
         setCurrentPage(1); // Redefine para a primeira página
      } catch (error) {
         toast.error('Ocorreu um erro ao buscar os movimentos.');
      } finally {
         setIsLoading(false);
      }
   };

   // Calcula o índice do primeiro e do último item a ser exibido na página atual
   const indexOfLastMovement = currentPage * itemsPerPage;
   const indexOfFirstMovement = indexOfLastMovement - itemsPerPage;

   // Cria uma lista de produtos para exibir na página atual
   const currentMovements = movements.slice(
      indexOfFirstMovement,
      indexOfLastMovement
   );

   // Calcula o número total de páginas
   const totalPages = Math.ceil(movements.length / itemsPerPage);

   const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
   };

   // Lógica para renderizar somente 15 itens por vez na paginação
   const renderPageNumbers = () => {
      const pageNumbers = [];
      const maxPageNumbersToShow = 5;
      const halfRange = Math.floor(maxPageNumbersToShow / 2);

      let startPage = Math.max(1, currentPage - halfRange);
      let endPage = Math.min(totalPages, currentPage + halfRange);

      if (currentPage <= halfRange) {
         endPage = Math.min(totalPages, maxPageNumbersToShow);
      }

      if (currentPage + halfRange >= totalPages) {
         startPage = Math.max(1, totalPages - maxPageNumbersToShow + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
         pageNumbers.push(
            <button
               key={i}
               onClick={() => handlePageChange(i)}
               className={currentPage === i ? 'active' : ''}
            >
               {i}
            </button>
         );
      }

      return pageNumbers;
   };

   const navigate = useNavigate();

   const handleRowClick = (id) => {
      navigate(`/movements/${id}`);
   };
   return (
      <Container>
         <Loading isLoading={isLoading} />
         <Title>Movimentos</Title>
         <p>
            Faça uma consulta nos movimentos registrados para acompanhar a
            logística do estoque
         </p>
         <form onSubmit={handleQueryParams}>
            <QueryContainer>
               <h4>Parâmetros de pesquisa</h4>
               <QueryForm>
                  <h4>Intervalo de datas</h4>
                  <div className="form-fields">
                     <div className="date-fields">
                        <label htmlFor="start_date">Data início:</label>
                        <input
                           type="date"
                           value={startDate}
                           onChange={(e) => setStartDate(e.target.value)}
                        ></input>
                     </div>
                     <div className="date-fields">
                        <label htmlFor="start_date">Data fim:</label>
                        <input
                           type="date"
                           value={endDate}
                           onChange={(e) => setEndDate(e.target.value)}
                        ></input>
                     </div>
                  </div>
               </QueryForm>
               <QueryForm>
                  <h4>Pesquisa refinada</h4>
                  <div className="form-fields">
                     <div className="input-fields-sm">
                        <label>ID:</label>
                        <input
                           value={id}
                           onChange={(e) => setId(e.target.value)}
                           type="text"
                        ></input>
                     </div>
                     <div className="input-fields">
                        <label>Selecione o PDV:</label>
                        <select
                           value={pdvId}
                           onChange={(e) => setPdvId(e.target.value)}
                        >
                           <option value="">Selecione um PDV</option>
                           {pdvs.map((pdv) => (
                              <option key={String(pdv.id)} value={pdv.id}>
                                 {pdv.pdv_name}
                              </option>
                           ))}
                        </select>
                     </div>
                     <div className="input-fields">
                        <label>Tipo de movimento:</label>
                        <select
                           value={moveType}
                           onChange={(e) => setMoveType(e.target.value)}
                        >
                           <option value="">Selecione um tipo</option>
                           <option value="in">Entrada</option>
                           <option value="out">Saída</option>
                        </select>
                     </div>
                     <div className="input-fields-sm">
                        <label>Nº documento:</label>
                        <input
                           type="text"
                           value={docNumber}
                           onChange={(e) => setDocNumber(e.target.value)}
                        ></input>
                     </div>
                     <div className="input-fields">
                        <label>Status do movimento:</label>
                        <select
                           value={movementFlag}
                           onChange={(e) => setMovementFlag(e.target.value)}
                        >
                           <option value="">Selecione o status</option>
                           <option value="confirmed">Confirmado</option>
                           <option value="deleted">Excluído</option>
                        </select>
                     </div>
                  </div>
               </QueryForm>
               <PesquisarBtn type="submit">Pesquisar</PesquisarBtn>
            </QueryContainer>
         </form>
         <TableHeader>
            <p>Total de movimentos: {movements.length}</p>
         </TableHeader>
         <TableData>
            <table>
               <thead>
                  <tr>
                     <th>ID</th>
                     <th>PDV</th>
                     <th>Tipo</th>
                     <th>Documento</th>
                     <th>Nº Documento</th>
                     <th>Data</th>
                     <th>Situação</th>
                  </tr>
               </thead>
               <tbody>
                  {currentMovements.length > 0 ? (
                     currentMovements.map((movement) => (
                        <tr
                           key={String(movement.id)}
                           onDoubleClick={() => handleRowClick(movement.id)}
                        >
                           <td style={{ textAlign: 'center' }}>
                              {movement.id}
                           </td>
                           <td>{movement.pdv_id}</td>
                           <td style={{ textAlign: 'center' }}>
                              {movement.move_type === 'in'
                                 ? 'ENTRADA'
                                 : 'SAÍDA'}
                           </td>
                           <td style={{ textAlign: 'center' }}>
                              {movement.doc_type}
                           </td>
                           <td style={{ textAlign: 'center' }}>
                              {movement.doc_number}
                           </td>
                           <td style={{ textAlign: 'center' }}>
                              {movement.created_at}
                           </td>
                           <td
                              className={
                                 movement.movement_flag === 'confirmed'
                                    ? 'confirmed-movement'
                                    : 'excluded-movement'
                              }
                              style={{ textAlign: 'center' }}
                           >
                              {movement.movement_flag === 'confirmed'
                                 ? 'CONFIRMADO'
                                 : 'EXCLUÍDO'}
                           </td>
                        </tr>
                     ))
                  ) : (
                     <tr>
                        <td colSpan="7" style={{ textAlign: 'center' }}>
                           Nenhum movimento encontrado
                        </td>
                     </tr>
                  )}
               </tbody>
            </table>
         </TableData>
         <TableFooter>
            <p>Total de páginas: {totalPages}</p>
         </TableFooter>
         <Pagination>
            <button
               onClick={() => handlePageChange(currentPage - 1)}
               disabled={currentPage === 1}
            >
               Anterior
            </button>
            {renderPageNumbers()}
            <button
               onClick={() => handlePageChange(currentPage + 1)}
               disabled={currentPage === totalPages}
            >
               Próximo
            </button>
         </Pagination>
         {/* Adicionado o parágrafo abaixo para exibir o total de páginas */}
      </Container>
   );
}
