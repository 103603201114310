import styled, { createGlobalStyle } from 'styled-components';
import * as colors from '../config/colors';
import 'react-toastify/dist/ReactToastify.css';

export const ActionsContainer = styled.div`
   display: flex;
   width: 100%;
   justify-content: start;
   margin: 24px 0;
   column-gap: 24px;
`;

export const PesquisarBtn = styled.button`
   width: 120px;
`;
